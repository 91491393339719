@import 'partials/variables';


/*Bootstrap Imports*/
@import 'bootstrap/bootstrap.scss';
@import 'partials/nav';
@import 'partials/footer';
@import 'partials/parallax';
@import 'partials/slick.scss';
@import 'partials/slick-theme.scss';

html, body{
    overflow-x: hidden;
}


body{
    font-family: $headingfont, sans-serif !important;
    //background: #FFFFFF;

}
::placeholder,select{
    font-family: $bodyfont;
}
.object-fit{
    @include object-fit(cover, 50% 50%);
}

//reset bootstrap focus glow
.wpv-filter-form .form-control, .btn:focus, .btn:active,button:focus  {
  border-radius: 0;
  box-shadow: none!important;
  outline: 0px!important;
  -webkit-box-shadow: none !important;
}
textarea, select, input, button { outline: none;}

.alignleft{
    float: left;
}
.hidden{
    opacity:0;
}
.show{
    opacity:1;
}
.center{
    text-align:center;
}
.fadein{
    opacity: 1;
    transition: opacity .15s ease-in-out;
    -moz-transition: opacity .15s ease-in-out;
    -webkit-transition: opacity .15s ease-in-out;
}
.bold{
    font-weight:$exbold;
}
.serif{
    font-family:$headingfont !important;
}
.sans-serif{
    font-family: $headingfont !important;
}
.absolute-center {
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center; // optional, but helps horizontally center text that breaks into multiple lines
}
.purple{color:$purple;}


.wpforms-field.wpforms-field-select{
    select {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          display: block !important;
          width: 100% !important;
          font-size: 16px !important;
          font-family: $bodyfont ;
          line-height: 1.75 !important;
          //color: #FFF !important;
          background-color: transparent !important;
          background-image: none !important;
          //border: 1px solid #cccccc !important;
          -ms-word-break: normal!important;
          word-break: normal!important;
    }
    &:after {
      font-family: 'fa-solid-900';
      content: '\f0d7';
      font-size: 17px;
      //color: #333;
      right: 15px;
      top: 76px;
      padding: 0 0 2px;
      position: absolute;
      pointer-events: none;
  }
}


//components
.acf-map {
    width: 100%;
    height: 1000px;
    img {
       max-width: inherit !important;
    }
}
.hero-section{
    h5{
        font-family:$subheadfont;
        font-weight: $normal;
        font-size: 20px;
        letter-spacing: .5px;
        color:#FFFFFF;
        position: absolute;
    }
    h1{
        font-family:$headingfont !important;
        color:#FFFFFF;
        line-height: 1em;
        font-size: 3vw;
        letter-spacing: -0.41px;
        font-weight: $exbold;
        position: absolute;
    }
    a{
        &:hover{text-decoration: none;}
    }
    span.hero-cta{
        text-transform: uppercase;
        font-family:$headingfont !important;
        font-weight: $bold;
        color:#FFFFFF;
        font-size: 1.6vw;
        letter-spacing: 0;
        transition:all .5s;
        &:before{
            content:'';
            display:block;
            height:4px;
            width:50px;
            position: relative;
            background: $lgtblue;
            margin-bottom:10px;
            transition:all .5s;
        }
        &:hover{
            color:$lgtblue;
            &:before{
                background: $lgtblue;
                //width:0px;
                //animation: grow-in .5s forwards  ease-out;
            }
        }
    }
    &.section-one{
        //height:820px;
        h1{
            position:absolute;
            top:175px;
            left:50px;
        }
        h5{
            position:absolute;
            top:150px;;
            left:50px;
        }

    }
    &.section-two{
        height:375px;
        h1{
            position: absolute;
            top:75px;
            left:50px;
        }
        h5{
            position: absolute;
            top:50px;;
            left:50px;
        }
        //height:40vh;
        overflow: hidden;
        &:before{
            transition: all .5s;
            opacity:0;
            position: absolute;
            content:'';
            //margin-left:-1.55em;
            background: transparent;
            width:0%;
            height:0%;
            //z-index:1
        }
    }
    .hero-section-1{
        //margin-top:25%;
        //height:80vh;
        height:750px;
        //overflow: hidden;
    }
    .hero-link-2,.hero-link-3{
        position:absolute;
        bottom:0px;
        z-index: 2;
    }

}
.hero-grid{
    height:750px;
    overflow:hidden;
}
.header-a{
    height:602px;
    color:#FFFFFF;
    .page-name{
        bottom: 0;
    }
}
.header-b{
    background: #FFFFFF;
    .page-name h1{color:$greyfont !important;}
    .pov-text{
        color:$greyfont !important;
        h4{
            font-family: $headingfont;
            font-size:24px;
        }
        p{
            font-size: 20px;
            font-family: $bodyfont;
            line-height: 35px;
        }
    }

}
.header-b-img{
    @include object-fit(cover);
}
.spacer{
    display: block;
    clear:both;
}
.spacer-small{
    display: block;
    position: relative;
    height:10vh !important;
}
.search-spacer{
    height:30vh;
}
.spacer-medium{
    height:50vh;
}
.spacer-large{
    height:75vh;
}
.column-container{
    margin-top:5rem !important;
    .pov-text{
        font-size: 30px;
        letter-spacing: 0.95px;
        line-height: 46px;
    }
    h4{
        font-weight: $bold;
        color:$greyfont !important;
        font-size: 30px;
        letter-spacing: 1.22px;
    }
    p{
        font-family: $bodyfont;
        color:$greyfont;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0.97px;
        line-height: 35px;
        a{
            text-transform: none!important;
            color:$greyfont !important;
            font-family:$bodybold;
            //text-decoration: underline;
            transition:all .5s;
            &:before{
                content:none;
            }
            &:hover{
                color:$purple !important;
            }
        }
    }
    ul{
        line-height: 39px;
        li{
            font-family: $bodyfont;
            font-weight: 300;
            font-size: 20px;
            list-style: square;
        }
    }
    hr{
        margin:60px 0 60px 0;
        border-top:1px solid #414042;

    }
    .button-link{
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 18px;
        color: $greyfont;
        letter-spacing:3px;
    }
    img{
        margin-bottom:30px;
    }
    a{
        &:before{
            content:'';
            display:block;
            height:4px;
            width:50px;
            position: relative;
            background: $purple;
            margin-bottom:10px;
            transition:all .5s;
        }
        &:hover{
            text-decoration: none;
            &:before{
                background: $purple;
                width:0px;
                animation: grow-in .5s forwards  ease-out;
            }
        }
    }
    .counter-container{margin-top:-9%;}
    .counter {
        color:$greyfont;
        font-family: $subheadlgt;
        font-size: 9.5em;
        }
}
.form-tooltip{
    margin-top: -20px !important;
    float: right!important;
    color:rgba($greyfont,.6);
    font-weight: $normal;
    text-decoration: underline !important;
    font-size: .8em !important;
    transition: all .5s;
    &:before{
        content:none !important;
    }
    &:hover{
        color:$purple;
        &:before{
            content:none !important;
        }
    }
}
#formsTooltip{
    background: rgba(#FFFFFF,.3);
    padding: 10px 10px 20px 10px;
    p{
        margin-top:1em;
        font-size: .95em;
        line-height: 1.4em;
    }
    div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
        background: transparent;
    }
}
.big-bottom-text{
    h2{
        font-size: 55px !important;
        color:$greyfont;
    }
}
.page-template-services,.page-template-no-bottom-section{
    .wpforms-field.wpforms-field-select{
        &:after {
          position: relative !important;
          left: 97% !important;
          top: -30px !important;

      }
    }
    div.wpforms-container-full .wpforms-form input[type=submit],
    div.wpforms-container-full .wpforms-form button[type=submit] {
    background-color: transparent !important; /* Grey background */
    border: none; /* Dark grey border */
    color: $greyfont; /* Black text color */
    font-size: 18px; /* Size of text */
    letter-spacing: .2em !important;
    padding: 0px; /* Distance between text and border */
    text-transform: uppercase;
    font-family: $headingfont;
    font-weight: $bold;
    margin-top: 20px;
    &:before{
        content:'';
        display:block;
        height:4px;
        width:50px;
        position: relative;
        background: $purple;
        margin-bottom:10px;
        transition:all .5s;
    }
    &:hover{
        &:before{
            //width:0px;
            //animation: grow-in .5s forwards  ease-out;
        }
    }
}
.request-a-quote.b{
    display: none !important;
}
    div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {margin:0px;padding:0px !important;}
    div.wpforms-container-full .wpforms-form input[type=submit]:hover,
    div.wpforms-container-full .wpforms-form input[type=submit]:active,
    div.wpforms-container-full .wpforms-form button[type=submit]:hover,
    div.wpforms-container-full .wpforms-form button[type=submit]:active,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
        background-color: transparent; /* Darker grey background */
        color:$purple !important ;
        border:none;}
}
//Page specific
.automotive{
    .column-container{
        margin-top:0px !important;
        background: $lgtblue;
        color:$greyfont;
    }
    .columntext{
        background: #FFFFFF;
        color:$greyfont;
    }
    .righttext{
        background: $lgtblue;
        color:$greyfont;
    }
    .bottom-section{
        margin-top: 0px;
        padding-top:75px;
        background:#FFFFFF;
    color:$greyfont;}
    .spacer-1{
        height:70vh !important;
    }
    .spacer-small{background: #FFFFFF;}
    .spacer-large{background: #FFFFFF;}
    .column-two,.column-three{
        margin-top:-30%;
        //this probably has to have some more targeted logic in case  someone uses another column component on this page
    }
    background: $lgtblue;

        h3{
            font-size: 30px;
            font-weight: $exbold;
            font-family: $headingfont;
            margin: 10px 0 0 0;
        }

            input, textarea{
                background: transparent !important;
                color:$greyfont !important;
            }
            select{
                border:0px !important;
                outline: 1px solid #414042 !important;
            }
            ::placeholder{
                color:rgba($greyfont,.5) !important;
            }
            div.wpforms-container-full .wpforms-form select {
                border-radius: 0px!important;
                font-family:$bodyfont;
                .placeholder{
                    color:rgba($greyfont,.5) !important;
                }
            }
                div.wpforms-container-full .wpforms-form input[type=submit],
                div.wpforms-container-full .wpforms-form button[type=submit] {
                background-color: transparent !important; /* Grey background */
                border: none; /* Dark grey border */
                color: $greyfont; /* Black text color */
                font-size: 18px; /* Size of text */
                letter-spacing: .2em !important;
                padding: 0px; /* Distance between text and border */
                text-transform: uppercase;
                font-family: $headingfont;
                font-weight: $bold;
                margin-top: 20px;
                &:before{
                    content:'';
                    display:block;
                    height:4px;
                    width:50px;
                    position: relative;
                    background: $purple;
                    margin-bottom:10px;
                    transition:all .5s;
                }
                &:hover{
                    &:before{
                        //width:0px;
                        //animation: grow-in .5s forwards  ease-out;
                    }
                }
            }
            div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
                border-color:$greyfont;
            }
            div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {margin:0px;padding:0px !important;}
            div.wpforms-container-full .wpforms-form input[type=submit]:hover,
            div.wpforms-container-full .wpforms-form input[type=submit]:active,
            div.wpforms-container-full .wpforms-form button[type=submit]:hover,
            div.wpforms-container-full .wpforms-form button[type=submit]:active,
            div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
            div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
                background-color: transparent; /* Darker grey background */
                color:$purple !important ;
                border:none;}


                .wpforms-field.wpforms-field-select{
                    &:after {
                      position: relative !important;
                      left: 97% !important;
                      top: -30px !important;

                  }
                }
}
.ocean,.ground, .government, .case-studies{
    background: #FFFFFF;
    input, textarea{
        background: transparent !important;
        color:$greyfont !important;
    }
    select{
        border:0px;
        outline: 1px solid #414042;
    }
    .column-container {background: #FFFFFF !important;}
    .column-two,.column-three{
        margin-top:0px;
    }

            h3{
                font-size: 30px;
                font-weight: $exbold;
                font-family: $headingfont;
                margin: 10px 0 0 0;
            }

                input, textarea{
                    background: transparent !important;
                    color:$greyfont !important;
                }
                ::placeholder{
                    color:rgba($greyfont,.5);
                }
                div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-field-select select {
                    color:rgba($greyfont,.5) !important;
                    font-family: $bodyfont
                }
                    div.wpforms-container-full .wpforms-form input[type=submit],
                    div.wpforms-container-full .wpforms-form button[type=submit] {
                    background-color: transparent !important; /* Grey background */
                    border: none; /* Dark grey border */
                    color: $greyfont; /* Black text color */
                    font-size: 18px; /* Size of text */
                    letter-spacing: .2em;
                    padding: 0px; /* Distance between text and border */
                    text-transform: uppercase;
                    font-family: $headingfont;
                    font-weight: $bold;
                    margin-top: 20px;
                    &:before{
                        content:'';
                        display:block;
                        height:4px;
                        width:50px;
                        position: relative;
                        background: $purple;
                        margin-bottom:10px;
                        transition:all .5s;
                    }
                    &:hover{
                        &:before{
                            //width:0px;
                            //animation: grow-in .5s forwards  ease-out;
                        }
                    }
                }
                div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
                    border-color:$greyfont;
                }
                div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {margin:0px;padding:0px !important;}
                div.wpforms-container-full .wpforms-form input[type=submit]:hover,
                div.wpforms-container-full .wpforms-form input[type=submit]:active,
                div.wpforms-container-full .wpforms-form button[type=submit]:hover,
                div.wpforms-container-full .wpforms-form button[type=submit]:active,
                div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
                div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
                    background-color: transparent; /* Darker grey background */
                    color:$purple !important ;
                    border:none;}
                    .wpforms-field.wpforms-field-select{
                        &:after {
                          position: relative !important;
                          left: 97% !important;
                          top: -30px !important;

                      }
                    }
}
.ground{
    .column-0{
        padding-top:0px  !important;
    }
}
.ocean{
    //background: $lgtblue;
    .column-container-3 {
        margin-top:0px !important;
    }
    .righttext.column-container{
        //background: #FFFFFF!important;
    }
    .twothirds-photo-right,.two-thirds-left-text, .spacer-small.spacer-0,.spacer-small.spacer-1,.spacer-2{
        background: $lgtblue !important;
    }
    .two-thirds-left-text{
        margin-top:0px !important;
    }
    .twothirds-photo-right img{
        margin-top:-25%;
    }
    .photocount-5{
        img{margin-top:0%!important;}
        background: #FFFFFF !important;
    }
    h2.ocean-callout{
        font-size: 3.5vw;
        color: $greyfont;
    }

}
.your-honest-guide-to-car-shipping{
    .spacer-6,.column-container-7,.column-5,.column-6,.spacer-small.bottom,.column-container-6,.spacer-5,.two-thirds-left-text.column-4,.column-container-5,.spacer-4,.column-3,.column-container-4,.spacer-small.spacer-3,.photocount-3 {
        background: $lgtblue;
    }
    .column-container-7,.column-6,.column-5,.column-container-6,.column-container-5,.column-container.column-4,  .two-thirds-left-text.column-3,.column-container-4{
        margin:0px !important;
    }
    .column-container-2{margin-bottom:25% !important;}
    .photocount-3 > .col-md-8{
        margin-top:-25%;
    }
    div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea,div.wpforms-container-full .wpforms-form input[type=email] {
        background: transparent !important;
        border-color:$greyfont;
    }

}
.got-questions{
    .column-container{
        margin-top:0px !important;
    }
}
.single-casestudy{
    .header-a{
        .eyelash{
            color:#FFFFFF;
            font-size: 20px;
            margin-bottom: 0px;
            font-family: TiemposHeadlineReg,serif;
        }
    }
}
body.error404{
    //background: url('../../assets/img/map-bg.jpg;');
    @extend .purple-blue-gradient;
    .navbar{
        background: #FFFFFF;
    }

}
.search{
    background: url('../../assets/img/map-bg.jpg;');
    background-size: cover;
    .search-term{
        font-family: $subheaditalic;
        color:$purple;
    }
    .search-heading{
        color:$greyfont;
    }
    .entry-details{
        color:#FFFFFF;
        position:relative;
        margin-top:-11%;
    }
    .button-link{
        color:#FFFFFF;
        &:before{
            content:'';
            display:block;
            height:4px;
            width:50px;
            position: relative;
            background: $purple;
            margin-bottom:10px;
            transition:all .5s;
        }
        &:hover{
            text-decoration: none;
            color:$lgtblue;
            &:before{
                background: $lgtblue;
                width:0px;
                animation: grow-in .5s forwards  ease-out;
            }
        }
    }
}
.request-a-quote{
    background: $purple;
    color:#FFFFFF;
    div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-field-select select {
         color:rgba(255,255,255,.7);
    }
    input{
        background: transparent !important;
        color:#FFFFFF !important;
    }
    h3{
        font-size: 1.8vw;
        font-weight: $exbold;
        font-family: $headingfont;
        margin: 10px 0 0 0;
    }
    ::placeholder{
        color:rgba(255,255,255,.7);
    }
        div.wpforms-container-full .wpforms-form input[type=submit],
        div.wpforms-container-full .wpforms-form button[type=submit] {
        background-color: transparent; /* Grey background */
        border: none; /* Dark grey border */
        color: #FFFFFF; /* Black text color */
        font-size: 18px; /* Size of text */
        padding: 0px; /* Distance between text and border */
        text-transform: uppercase;
        line-height: 50px;
        font-family: $headingfont;
        font-weight: $bold;
    }
    div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {margin:0px;padding:0px !important;}
    div.wpforms-container-full .wpforms-form input[type=submit]:hover,
    div.wpforms-container-full .wpforms-form input[type=submit]:active,
    div.wpforms-container-full .wpforms-form button[type=submit]:hover,
    div.wpforms-container-full .wpforms-form button[type=submit]:active,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
        background-color: transparent; /* Darker grey background */
        color:$lgtblue;
        border:none; }
    &.b{
        cursor: pointer;
        .fa-arrow-circle-right{
            color:#FFFFFF;
            font-size:1.5em;
            line-height: 2.3em;
            margin-left:5px;
        }
    }

}
.modal#requestAQuote{
    .modal-logo{
        width:75px;
        float:right;
        margin-left:60%
    }
    .modal-content {
        border-radius: 0px;
        border:0px;
    }
    select{
        border:0px;
        outline: 1px solid #414042;
    }
    > .modal-dialog {
        max-width: 800px !important;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background: $purple;
        color:#FFFFFF;
        margin-bottom:1rem;
        border-radius: 0px;
    }
    .nav-link{
        color:$greyfont;
    }
    .modal-header {
        background: $purple;
        color:#FFFFFF;
        border-radius: 0px;
        span{
            color:#FFFFFF;
        }
    }
    .modal-body{
        padding: 2rem 3rem 3rem 3rem;
    }
    div.wpforms-container-full .wpforms-form .wpforms-field-label {
        color:$greyfont;
    }
    input, textarea{
        background: transparent !important;
        color:$greyfont !important;
    }
    ::placeholder{
        color:rgba($greyfont,.5) !important;
    }
    div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-field-select select {
        color:rgba($greyfont,.5) !important;
        font-family: $bodyfont;
    }
    div.wpforms-container-full .wpforms-form select {
        border-radius: 0px!important;
        .placeholder{
            color:rgba($greyfont,.5) !important;
        }
    }
        div.wpforms-container-full .wpforms-form input[type=submit],
        div.wpforms-container-full .wpforms-form button[type=submit] {
        background-color: transparent !important; /* Grey background */
        border: none; /* Dark grey border */
        color: $greyfont; /* Black text color */
        font-size: 18px; /* Size of text */
        padding: 0px; /* Distance between text and border */
        text-transform: uppercase;
        font-family: $headingfont;
        font-weight: $bold;
        letter-spacing: .2em;
        margin-top: 20px;
        &:before{
            content:'';
            display:block;
            height:4px;
            width:50px;
            position: relative;
            background: $purple;
            margin-bottom:10px;
            transition:all .5s;
        }
    }
    div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
        border-color:$greyfont;
    }
    div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {margin:0px;padding:0px !important;}
    div.wpforms-container-full .wpforms-form input[type=submit]:hover,
    div.wpforms-container-full .wpforms-form input[type=submit]:active,
    div.wpforms-container-full .wpforms-form button[type=submit]:hover,
    div.wpforms-container-full .wpforms-form button[type=submit]:active,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
        background-color: transparent; /* Darker grey background */
        color:$purple !important ;
        border:none;}
        .wpforms-field.wpforms-field-select{
            &:after {
              position: relative !important;
              left: 97% !important;
              top: -30px !important;

          }
        }
}
.bottom-section{
    margin-top: -20% !important;
    .bottom-pov{
        margin:30% 0 20% 0;
        h4{
            font-size:20px;
            font-family: $subheadfont;
        }
        h2{
            font-weight:$exbold;
            font-size: 55px;
        }
        p{
            font-size: 20px;
            line-height:35px;
            font-family: $bodyfont;

        }
        a{
            text-transform: uppercase;
            font-family:$headingfont !important;
            font-weight: $bold;
            font-size: 22px;
            letter-spacing: 2px;
            transition:all .5s;
            &:before{
                content:'';
                display:block;
                height:4px;
                width:50px;
                position: relative;
                margin-bottom:10px;
                transition:all .5s;
            }
            &:hover{
                text-decoration: none;
                &:before{
                    //width:0px;
                    //animation: grow-in .5s forwards  ease-out;
                }
            }
        }
    }
    &.home{
        .bottom-pov{
            h4{color:#FFFFFF;}
            h2{color:#FFFFFF;}
            p{color:#FFFFFF;}
            a{color:#FFFFFF;
                &:before{background: $lgtblue;}
                &:hover{color:$lgtblue;
                    &:before{background: $lgtblue;
                    }
                }
            }
        }
    }
    &.our-company{
        .bottom-pov{
            h4{color:$greyfont;}
            h2{color:$greyfont;}
            p{color:$greyfont;}
            a{color:$greyfont;
                &:before{background: $purple;}
                &:hover{color:$purple;
                    &:before{background: $purple;}
                }
            }
        }
    }
    &.ocean{
        .bottom-pov{
            h4{color:$greyfont;}
            h2{color:$greyfont;}
            p{color:$greyfont;}
            a{color:$greyfont;
                &:before{background: $purple;}
                &:hover{color:$purple;
                    &:before{background: $purple;}
                }
            }
        }
    }
    &.ground{
        .bottom-pov{
            h4{color:$lgtblue;}
            h2{color:#FFFFFF;}
            p{color:#FFFFFF;}
            a{color:#FFFFFF;
                &:before{background: $lgtblue;}
                &:hover{color:$lgtblue;
                    &:before{background: $lgtblue;}
                }
            }
        }
    }
    &.government{
        .bottom-pov{
            h4{color:$greyfont;}
            h2{color:$greyfont;}
            p{color:$greyfont;}
            a{color:$greyfont;
                &:before{background: $purple;}
                &:hover{color:$purple;
                    &:before{background: $purple;}
                }
            }
        }
    }
    &.case-studies{
        .bottom-pov{
            h4{color:$greyfont;}
            h2{color:$greyfont;}
            p{color:$greyfont;}
            a{color:$greyfont;
                &:before{background: $purple;}
                &:hover{color:$purple;
                    &:before{background: $purple;}
                }
            }
        }
    }
    &.error404{
        margin-top:0px !important;
        .bottom-pov{
            margin:0%;
            h4{color:#FFFFFF;}
            h2{color:#FFFFFF;}
            p{color:#FFFFFF;}
            a{color:#FFFFFF;
                &:before{background: $lgtblue;}
                &:hover{color:$lgtblue;
                    &:before{background: $lgtblue;}
                }
            }
        }
    }
    &.contact{
        .bottom-pov{
            margin:0px;
            h4{color:#FFFFFF;}
            h2{color:#FFFFFF;}
            p{color:#FFFFFF;}
            .link-container{
                //position: absolute;
                //bottom:50px;
            }
            &.image-container{
                &:before{
                    /*content:'';
                    background: url('../../assets/img/circle-blue.png;');
                    position:absolute;
                    top: 36%;
                    right: 83%;
                    width:290px;
                    height:290px;
                    z-index: 100;*/
                }
            }
            a{color:#FFFFFF;
                &:before{background: $lgtblue;}
                &:hover{color:$lgtblue;
                    &:before{background: $lgtblue;}
                }
            }
        }
    }

}
.testimonialcontainer{
    margin-top: -120px;
    height:710px;
    h3{
        font-size:68px;
        font-family: $subheaditalic;
        font-style: italic;
        color:#FFFFFF;}
    h6{
        font-family: $subheadfont;
        font-size: 15px;
        color:#FFFFFF;
        margin:0px;
    }
    .testimonial-img{
        @include object-fit(cover);
        width:100%;
        height:710px;
    }
}
.circle-img{
    margin-top: -171px;
    transition:all 1s;
    transition-timing-function: ease-in-out;
    position: relative;
    z-index: 100;
}
.testimonial{height:710px;
    display:flex !important;
    //padding-top:14.5%;
}
.shipping-steps{
    background: #231f20;
    padding:13% 0 8% 0;
    color:#FFFFFF;
    h4{
        font-size: 24px;
    }
    p{color:#FFFFFF;
    font-family:$bodyfont;
    font-size: 20px;}
    [class*="number-icon--"]{
        font-family: $subheadlgt;
        font-size:49px;
        display: block;
        text-align: center;
        border:1px solid #FFFFFF;
        border-radius: 38px;
        width:76px;
        height:76px;
        margin-bottom:20px;

    }
    img.image-icon{
        margin-bottom:20px;
        width:150px;
        //height:76px;
    }
}
.two-ways{
    h4{
        position:absolute;
        bottom:25px;
        left:50px;
        font-size:22px;
        letter-spacing: 1px;
        color:#FFFFFF;
        text-transform: uppercase;
        &:before{
            content:'';
            display:block;
            height:4px;
            width:50px;
            position: relative;
            background: $lgtblue;
            margin-bottom:10px;
            transition:all .5s;
        }
    }
}
.contact{
    .wpforms-submit.contact-submit, div.wpforms-container-full .wpforms-form input[type=submit],
    div.wpforms-container-full .wpforms-form button[type=submit]{
        background-color: transparent !important; /* Grey background */
        border: none !important; /* Dark grey border */
        color: $greyfont !important; /* Black text color */
        font-size: 18px !important; /* Size of text */
        padding: 0px !important; /* Distance between text and border */
        text-transform: uppercase !important;
        font-family: $headingfont !important;
        font-weight: $bold !important;
        margin-top: 20px !important;
        letter-spacing: .2em;
        &:before{
            content:'';
            display:block;
            height:4px;
            width:50px;
            position: relative;
            background: $purple;
            margin-bottom:10px;
            transition:all .5s;
        }

    }
    div.wpforms-container-full .wpforms-form input[type=submit]:hover,
    div.wpforms-container-full .wpforms-form input[type=submit]:active,
    div.wpforms-container-full .wpforms-form button[type=submit]:hover,
    div.wpforms-container-full .wpforms-form button[type=submit]:active,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
    div.wpforms-container-full .wpforms-form .wpforms-page-button:active{
        color:$purple !important ;
        border:none;}
    .callout-box{
        background: $purple;
        padding:15% 12% 15% 10%;
        z-index: 200;
        position: relative;
        color:#FFFFFF !important;
        h4{color:#FFFFFF !important;}
        p{
            color:#FFFFFF !important;
            a{
                color:#FFFFFF !important;
                text-decoration: none;
                &:hover{
                    color:$lgtblue   !important;
                }
            }
        }
        .title{
            letter-spacing: .2em;
            &:before{
                content:'';
                display:block;
                height:4px;
                width:50px;
                position: relative;
                background: $lgtblue;
                margin-bottom:10px;
                transition:all .5s;
            }
        }
    }
    .bottom-section{
        margin-top:-10%;
    }
}
.wpforms-confirmation-container-full,.wpforms-confirmation-container-full p:last-of-type, div[submit-success] > .wpforms-confirmation-container-full p:last-of-type {
    background: $purple !important;
    border: none!important;
    color:#FFFFFF !important;
}
body .iti--allow-dropdown, div.wpforms-container .wpforms-form .iti--allow-dropdown, div.wpforms-container-full .wpforms-form .iti--allow-dropdown, #wpforms-conversational-form-page .iti--allow-dropdown{
    color:$greyfont !important;
}

//animation
@keyframes grow-in {
    0%{ width:0px;}
    100%{width:50px;}
}
@media screen and (min-width: 1700px) and (max-width:3000px) {
    .testimonialcontainer{
        height:750px;
        .testimonial-text{
            height:200px;
            margin-top:-27% !important;
        }
        .testimonial-img{
            width:100% !important;
            height: 750px !important;
        }
        .testimonial{
            height:750px;
        }
    }

}
//1200px down to 992px (between Large up to XL so breakpoints should be col-LG-)
@include media-breakpoint-down(xl) {
    .home,.search,.error404{
        .nav-a{display: flex !important;}
        .nav-b{display: none !important;}
    }
    .testimonialcontainer{
        .testimonial-img{
            width:100%;
            height:710px;
        }
        .testimonial-text{
            height:200px;
            margin-top:-28%;
        }
    }
}

//991px down to 768px (between Med up to Large so breakpoints should be col-MD-)
@include media-breakpoint-down(lg) {
    .hero-section{
        h5{font-size: 2.4vw;}
        h1{font-size: 5vw;}
        span.hero-cta{font-size: 2.5vw;}
        &.section-one{
            //height:978px;
            h1{
                top:183px;
                left:50px;
            }
            h5{
                top:150px;;
                left:50px;
            }

        }
        &.section-two{
            h1{
                top: 80px;
            }
        }
    }
    .column-container{
        margin-top:5rem !important;
        .pov-text{
            font-size: 1.2em;
            letter-spacing: 0.95px;
            line-height: 1.5em;
        }
        h4{
            font-size: 1.6em;
            letter-spacing: 1.22px;
        }
        p{
            font-size: 1em;
            letter-spacing: .9px;
            line-height: 1.8em;
        }
        ul{
            line-height: 1.8em;
            padding-left: 1em;
            li{
                font-size: 1em;
                letter-spacing: .9px;
                line-height: 1.6em;
            }
        }
        hr{margin:45px 0 45px 0;}
        .button-link{font-size: 1em;}
        .counter-container{margin-top:-9%;}
        .counter {font-size: 6.5em;}
        .big-bottom-text h2 {
            font-size: 2em !important;
        }
    }
    .spacer-medium {
        height: 25vh;
    }
    .bottom-section {
        .bottom-pov{
            h2 {font-size: 3em;}
            h4 {font-size: 18px;}
        }
    }

    .testimonialcontainer{
        margin-top:-65px;
        height:475px;
        overflow:hidden;
        h3 {
            font-size: 3em;
        }
        .testimonial-text{
            height:200px;
            margin-top:-47%;
        }
        .testimonial-img{
            width:100%;
            height:475px;
        }
    }
    .circle-img{
        margin-top:-76px;
    }
    .footer{
        h2 {
            font-size:1.3em;
        }
        a,p{
            font-size: 1em;
            line-height: 1.8em;
        }
        .wpforms-container-full.newsletter .wpforms-submit.newsletter{
            font-size:1em !important;
            margin-top:0px !important;
        }
    }
    .spacer-large{height:50vh;}
    .contact{
        .column-1{
            margin-top:0px !important;
        }
    }
    .search{
        .entry-details {
            margin-top: -20%;
        }
    }
    .navbar a.nav-link {
    font-size: 1.5vw;}

}
//768px down to 576px (between Small up to Med so breakpoints should be col-SM-)
@include media-breakpoint-down(md) {
    .header-a {
        height: 300px;
    }
    .column-three{
        margin-top:25px;
    }
    .spacer-medium{
        height:15vh;
    }
    .spacer-large{
        height:33vh;
    }

    .testimonialcontainer{
        margin-top:-47px;
        overflow:hidden;
        height:475px;
        h3 {
            font-size: 2em;
        }
        .testimonial-text{
            height:200px;
            margin-top:-61% !important;
        }
        .testimonial-img{
            width:100%;
            height:475px;
        }
    }
    .big-bottom-1{
        margin-left:3rem;
    }
    .shipping-steps p {
        font-size:1em;
    }
    .request-a-quote h3 {
    font-size: 2.7vw;}
    .search .entry-details {
    margin-top: -32%;}
    .search-spacer{
        height:15vh;
    }
    h2.ocean-callout{
        font-size: 4.5vw !important;
        color: $greyfont;
    }

}
//576px and below (between XS up to Sm so breakpoints should be col-)
@include media-breakpoint-down(sm) {

    .hero-section{
        h5{font-size: 3.5vw;}
        h1{font-size: 7vw;}
        span.hero-cta{font-size: 3.5vw;
            &:before{
                margin-bottom: 2px;
            }
        }
        &.section-one{
            height:510px;
            h1{
                top: 94px;
                left:50px;
            }
            h5{
                top: 73px;
                left:50px;
            }

        }
        &.section-two{
            height:310px;
            h1{
                top: 85px !important;
            }
            h5{
                top: 66px !important;
            }
        }
    }
    .bottom-section {
        .bottom-pov{
            h2 {font-size: 2em;}
            h4 {font-size: 18px;}
        }
    }
    .navbar a.nav-link {
    font-size: 3.5vw;}
    .automotive{
        .column-three{
        margin-top:0%;
        }
        .spacer-1{
            height:20vh !important;
        }
        .extra-line{
            display:none !important;
        }
        .big-bottom-1{
            margin-top:5rem;
        }
        .wpforms-field.wpforms-field-select{
            select{
                border-left:1px solid #000000 !important;
                border-right:1px solid #000000 !important;
            }
            &:after {
              position: relative !important;
              left: 92% !important;
              top: -30px !important;

          }
        }
    }
    .page-template-services,.page-template-no-bottom-section{
        .wpforms-field.wpforms-field-select{
            select{
                border-left:1px solid #000000 !important;
                border-right:1px solid #000000 !important;
            }
            &:after {
              position: relative !important;
              left: 92% !important;
              top: -30px !important;

          }
        }
    }
    .ground{
        .spacer-0,.spacer-1,.spacer-2{
            display:none;
        }
        .extra-line{
            display:none !important;
        }
        .column-two{
            margin-bottom:30px;
        }
        .wpforms-field.wpforms-field-select{
            select{
                border-left:1px solid #000000 !important;
                border-right:1px solid #000000 !important;
            }
            &:after {
              position: relative !important;
              left: 92% !important;
              top: -30px !important;

          }
        }
    }
    .our-company{
        .spacer-0{
            display:none;
        }
        .column-three{
            margin-top:0px !important;
        }
    }
    .ocean{
        .spacer-3, .extra-line{
            display: none !important;
        }
        .column-two{
            margin-bottom:30px;
        }
        .spacer-small.spacer-1,.spacer-2,.two-thirds-left-text{
            background: #FFFFFF !important;
        }
        .wpforms-field.wpforms-field-select{
            select{
                border-left:1px solid #000000 !important;
                border-right:1px solid #000000 !important;
            }
            &:after {
              position: relative !important;
              left: 92% !important;
              top: -30px !important;

          }
        }
    }
    .navbar.internal {
        width:inherit !important;
    }
    .column-container{
        .big-bottom-text h2 {
            font-size: 1.5em !important;
        }
    }
    .government{
        .column-container{
            margin-top:0px;
        }
        .spacer-small{height:8vh !important;}
        .spacer-2{display: none !important;}
    }
    .contact{
        .column-container{
            margin-top:0px !important;
            &.two-thirds-left-text {
                padding-left:2rem !important;
            }
        }
    }
    .single-casestudy{
        .header-a{
            h1{
                font-size: 1.5rem !important;
            }
        }
        .column-three {
            margin-top:0px !important;
        }
    }
    .column-container img {
        //margin-bottom:0px !important;
    }
    div.wpforms-container-full .wpforms-form .wpforms-field-label {
    font-size: 12px !important;}
    .home,.search,.error404{
        .nav-a{display: none !important;}
        .nav-b{display: flex  !important;
        z-index: 1111;}
    }
    .request-a-quote h3 {
    font-size: 4vw;}
    h2.ocean-callout{
        font-size: 7.5vw !important;

    }


    .testimonialcontainer{
        height:460px;
        margin-top: -67px;
        .testimonial-text{
            height:200px;
            margin-top:-80% !important;
        }
        .testimonial-img{
            width:100%;
            height: 460px;
        }
    }
    .copyright p {
    color: #fff;
    font-size: 9px;}
    .search {
        .entry-details {
        margin-top:10%;
        color:$greyfont;
        }
        .button-link{
            color:$greyfont;
        }
    }
    .home{
        .request-a-quote h3 {
            font-size: 7vw;
        }
        .wpforms-confirmation-container-full{
            padding:0px !important;
        }
    }
    .header-b-img{
        height:310px;
    }
    .modal#requestAQuote{
        .modal-logo{
            margin-left:15%
        }
        .modal-body {
            padding: 2rem 1rem 0rem;

        }
        .wpforms-field.wpforms-field-select{
            select{
                border-left:1px solid #000000;
                border-right:1px solid #000000;
            }
            &:after {
              position: relative !important;
              left: 92% !important;
              top: -30px !important;

          }
        }
    }
    .got-questions{
        .twothirdsimageright{
            margin-bottom:0px !important;
            img{
                margin-bottom:0px !important;
            }
        }
    }
    .bottom-section.government{
        background:url('#{$root}wp-content/uploads/2020/05/gov_map_background_1500x1150-1.jpg') !important;
        background-size: cover !important;
        background-position: 50% 50% !important;
    }
    .contact{
        .header-a{
            background: url('#{$root}wp-content/uploads/2020/05/contact_hero_1500x600-1-scaled.jpg') !important;
            background-size: cover !important;
            background-position: 50% 50% !important;
        }
    }
    .keeping-troops-on-the-move{
        .header-a{
            background: url('#{$root}wp-content/uploads/2020/05/plane.jpg') !important;
            background-size: cover !important;
            background-position: 50% 50% !important;
        }
    }
}

@include media-breakpoint-down(xs) {
    .testimonialcontainer{
        height:460px;
        margin-top: -67px;
        .testimonial-text{
            height:200px;
            margin-top:-89% !important;
        }
        .testimonial-img{
            width:100%;
            height: 460px;
        }
        .testimonial{
            height:460px;
        }
        }
    .modal-title{
        font-size: 1em;
        line-height:2.2em;
    }


}
