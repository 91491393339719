/* Parallax base styles
 --------------------------------------------- */

 .parallax2 {
   height: 100vh;
   overflow-x: hidden;
   overflow-y: auto;
   -webkit-perspective: 300px;
   perspective: 300px;
   background-size: cover;
   z-index:2;
 }
 .parallax2__group {
   position: relative;
   height: 93vh;
   -webkit-transform-style: preserve-3d;
   transform-style: preserve-3d;
 }

 .parallax2__layer {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
 }

 .parallax2__layer--fore {
   -webkit-transform: translateZ(90px) scale(.7);
   transform: translateZ(90px) scale(.7);
   z-index: 1;
 }

 .parallax2__layer--base {
   -webkit-transform: translateZ(-150px);
   transform: translateZ(-150px) scale(1.5);
   z-index: 4;
 }

 .parallax2__layer--back {
   -webkit-transform: translateZ(-300px) scale(2);
   transform: translateZ(-300px) scale(2);
   z-index: 3;
 }

 .parallax2__layer--deep {
   -webkit-transform: translateZ(-600px) scale(3);
   transform: translateZ(-600px) scale(3);
   z-index: 2;
 }


 .parallax {
   font-size: 200%;
 }

.left-text{
    text-align:right;
}
.right-text{
    text-align: left;
    left:50%;
}
#group1, #group2, #group3, #group4{
    height:70vh;
}
