.footer{
    background: #414042;
    color:#FFFFFF;
    padding:7% 0 7% 0;
    a{
            transition: all .5s;
        &:hover{
            color:$lgtblue;
            text-decoration: none;
        }
    }
    h2{
        font-size:24px;
        font-family: $headingfont;
        font-weight:$exbold;
        letter-spacing: 1px;
    }
    p,a{
        font-size: 20px;
        line-height: 35px;
        font-family: $subheadfont;
        color:#FFFFFF;
    }
    ul{
        padding-left:0px;
        li{
            a{color:#FFFFFF;
                transition:all .5s;
                &:hover{
                    color:$lgtblue;
                    text-decoration: none;
                }

            }
        }
    }

    .wpforms-container-full.newsletter{
        input, textarea{
            background: transparent !important;
            color:#FFFFFF !important;
            border:1px solid #FFFFFF!important;

        }

        ::placeholder{
            color:rgba(#FFFFFF,.7);
        }


        div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
            //border:1px solid #FFFFFF!important;
        }
        div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {margin:0px;padding:0px !important;}
        div.wpforms-container-full .wpforms-form input[type=submit]:hover,
        div.wpforms-container-full .wpforms-form input[type=submit]:active,
        div.wpforms-container-full .wpforms-form button[type=submit]:hover,
        div.wpforms-container-full .wpforms-form button[type=submit]:active,
        div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
        div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
            background-color: transparent; /* Darker grey background */
            color:$lgtblue !important ;
            border:none;}
        .wpforms-submit.newsletter, div.wpforms-container-full .wpforms-form input[type=submit],
        div.wpforms-container-full .wpforms-form button[type=submit]{
            background-color: transparent !important; /* Grey background */
            border: none !important; /* Dark grey border */
            color: #FFFFFF !important; /* Black text color */
            font-size: 18px !important; /* Size of text */
            padding: 0px !important; /* Distance between text and border */
            text-transform: uppercase !important;
            font-family: $headingfont !important;
            font-weight: $bold !important;
            margin-top: 20px !important;
            letter-spacing: .2em;
            &:before{
                content:'';
                display:block;
                height:4px;
                width:50px;
                position: relative;
                background: $lgtblue;
                margin-bottom:10px;
                transition:all .5s;
            }
            &:hover{
                color:$lgtblue !important ;
                &:before{
                    //width:0px;
                    //animation: grow-in .5s forwards  ease-out;
                }
            }

        }
    }


}
 .copyright{
    background: #231f20;
    padding:24px 0 15px 0;
    p{color:#FFFFFF;
    font-size: 14px;
    font-family: $subheadfont;
    }

}
ul.social-links{
    li{
        display:inline-block;
    }
    a{
        transition: all .5s;
        color:rgba(#FFFFFF,.6);
        &:hover{
            color:$lgtblue !important;
            text-decoration: none;
        }
    }

    .social-icon{
       height:25px;
       width:25px;
    }
}
