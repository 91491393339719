/* Icon 1 */

.animated-icon1{
width: 30px;
height: 20px;
position: relative;
margin: 0px;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;
cursor: pointer;
    span{
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    //border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    //background: $purple;
        &:nth-child(1){
            top: 0px;
        }
        &:nth-child(2){
            top: 10px;
        }
        &:nth-child(3){
            top: 20px;
        }
        &:nth-child(4){
            top: 0px;
        }

    }
    &.open{
        margin-top: 14px;
    }
    &.open span{
        &:nth-child(1){
            top: 11px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
        }
        &:nth-child(2){
            opacity: 0;
            left: -60px;
        }
        &:nth-child(3){
            top: 11px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }
}
.animated-icon1.dark{
    span{
    background: $purple;
    }
}
.animated-icon1.light{
    span{
    background: #FFFFFF;
    }
}

.navbar {
    padding:1em 0 1em 0 !important;
    height:100px;
    background: transparent;
    //border-bottom:1px solid #CCCCCC;
    transition:all .5s;
    a.nav-link{
        font-size: 13px;
        text-transform: uppercase;
        color:$greyfont;
        font-weight:$bold;
        letter-spacing: 1px;

    }
    .collapse.show{
            background: #FFFFFF;
            border-right:.5px solid #EEEEEE;
        .menu-main-menu-container{
            margin-top:0px;
            background: #FFFFFF;
            padding-bottom:5%;
            padding-top:3%;
            border-bottom: 8px solid $purple;
            //box-shadow: 1px 3px 3px #7b5e84;
            //border-right:.5px solid #EEEEEE;

            a.nav-link{
                padding-left: 95px;
            }
        }
    }
    .navbar-brand{
        height:36px;
        width:100%;
        &.navlogo-light{
            background: url(../../assets/img/logo-light.png);
            background-size: cover;
        }
        &.navlogo-dark{
            background: url(../../assets/img/logo-dark.png);
            background-size: cover;
        }
    }
    .navbar.internal .navbar-nav .dropdown-menu {
        padding-left:0px !important;
    }
    .dropdown-menu {
        border-radius: 0px;
    }
    .dropdown-item{
        color:$greyfont;
        text-transform: uppercase;
        font-size: .8em;
    }
    .dropdown-item:hover {
        background: $lgtblue;
    }
    &.internal{
        background: transparent;
        width: 325px !important;
        .menu-item{
            //padding-left:50px
        }
        a.nav-link{
            padding-left:95px;
            &:hover{
                background: $purple;
                color:#FFFFFF;
            }
        }
        .menu-main-menu-container{
            background: #FFFFFF;
            padding:10% 0 2% 0;
        }
        //border-bottom:none;
        .navbar-brand{
            position: absolute;
            z-index:100;
            left: 80px;
            //top: 8px;
            transition:all .5s;
        }
        .navbar-nav .dropdown-menu {
            //padding-left: 70px;
            border:none;
            background: #f5f5f5;
            .dropdown-item{
                color:$greyfont;
                text-transform: uppercase;
                font-size: .8em;
                padding: 0.65rem 0 0.65rem 102px;
            }
        }
    }
    &.navbarattop{
        background: transparent;
    }
    &.navbarscrolled{
        background: #FFFFFF !important;
        border-right:.5px solid #EEEEEE;
        border-bottom: 8px solid $purple !important;
        //box-shadow: 1px 1px 3px #7b5e84;
        &.menu-main-menu-container{
            //border-bottom: 8px solid $purple !important;
        }
    }
    .navbar-toggler {
        padding-left:0px;
    }


}
.got-questions{
    /*.navbar-brand{
        height:36px;
        width:100%;
        &.navlogo-light{
            background: url(../../assets/img/logo-all-white.png);
            background-size: cover;
        }
    }*/
}


.navbar-form-search {
  .form-group{
      input{
          border:1px solid $purple;
          border-radius: 0px;
      }
      ::placeholder{
          color:rgba($greyfont,.5);
      }
  }
  .btn {
    border: 0;
    background: transparent;
    font-size: 18px;
  }
}



.nav-a{
.navbar-form-search {
  position: relative;
  .form-group{
        margin-top: -38px;
  }
  .search-form-container {
    text-align: right;
    position: absolute;
    width: 193px;
    overflow: hidden;
    right: 50px;
    top: 0;
    z-index: 9;
    transition: all .3s ease-in-out;
    &.hdn {
      width: 0;
    }

    .search-input-group {
      width: 190px;
    }
  }
}
.navbar-brand{
    &.navlogo-dark{
        background-size: contain;
        background-repeat: no-repeat;
    }
}
}

.nav-b{
    #hide-search-input-container{display:none;}
    .form-group{
        margin-bottom:0px;
    }
    .navbar-form-search{
        transition: all .3s ease-in-out;
        margin-top:25px;
    }
    .search.btn{
        position: absolute;
        left: 80%;
        top: 40%;
    }
}
