

/*Color Variables*/
$black: #000000;
$white: #FFFFFF;
$purple:#720094;
$lgtblue:#1cffd4;
$greyfont:#414042;



/*fonts*/
$headingfont:'SharpSansExBold', sans-serif;
$subheadfont:'TiemposHeadlineReg', serif;
$subheaditalic:'TiemposHeadlineLgtItalic', serif;
$subheadlgt:'TiemposHeadlineLgt', serif;
$bodyfont:'TiemposTextReg',serif;
$bodybold:'TiemposTextSemibold',serif;

$exbold: 900;
$bold:600;
$semi-bold: 500;
$normal: 400;


$fontroot: 'https://www.daloshipping.com/';//for Prod
//$fontroot: 'http://daloshipping-staging.com/';//for Stage
//$fontroot: 'http://localhost:3000/4.6.20-DALO/';//for Localdev
//$fontroot: 'http://192.168.7.57:3000/4.6.20-DALO/';//for mobile testing

$root : $fontroot;

/*Custom Fonts*/
@font-face {
  font-family: 'SharpSansExBold';
  src:  url('#{$fontroot}wp-content/themes/dalo/assets/fonts/SharpSans/SharpSans-Extrabold.woff2') format('woff2'),
        url('#{$fontroot}wp-content/themes/dalo/assets/fonts/SharpSans/SharpSans-Extrabold.woff') format('woff');
    font-style: normal;
    font-weight: $exbold;
}
@font-face {
  font-family: 'TiemposHeadlineReg';
  src:  url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Headline/tiempos-headline-web-regular.woff2') format('woff2'),
        url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Headline/tiempos-headline-web-regular.woff') format('woff');
    font-style: normal;
    font-weight: $normal;
}
@font-face {
  font-family: 'TiemposHeadlineLgt';
  src:  url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Headline/tiempos-headline-web-light.woff2') format('woff2'),
        url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Headline/tiempos-headline-web-light.woff') format('woff');
    font-style: normal;
    font-weight: $normal;
}
@font-face {
  font-family: 'TiemposHeadlineLgtItalic';
  src:  url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Headline/tiempos-headline-web-light-italic.woff2') format('woff2'),
        url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Headline/tiempos-headline-web-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: $normal;
}

@font-face {
  font-family: 'TiemposTextReg';
  src:  url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Text/tiempos-text-web-regular.woff2') format('woff2'),
        url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Text/tiempos-text-web-regular.woff') format('woff');
    font-style: normal;
    font-weight: $normal;
}
@font-face {
  font-family: 'TiemposTextSemibold';
  src:  url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Text/tiempos-text-web-semibold.woff2') format('woff2'),
        url('#{$fontroot}wp-content/themes/dalo/assets/fonts/Tiempos-Text/tiempos-text-web-semibold.woff') format('woff');
        font-style: normal;
        font-weight: $semi-bold;
}






@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}em;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}
@mixin object-fit($fit: cover, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
	  -o-object-position: $position;
	     object-position: $position;
  	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
  	font-family: 'object-fit: #{$fit}';
  }
}

@mixin color-gradient($color1, $color2, $color3, $opacity){
background-image: -webkit-linear-gradient(45deg, $color1 10%, $color2 52%, $color3 91%);
background-image: -o-linear-gradient(45deg, $color1 10%, $color2 52%, $color3 91%);
background-image: linear-gradient(45deg, $color1 10%, $color2 52%, $color3 91%);
opacity:$opacity;
}
.purple-blue-gradient{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#720094+0,26e2cd+100&0.38+0,0.76+0,0.76+100 */
background: -moz-linear-gradient(top, rgba(114,0,148,1) 0%, rgba(38,226,205,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(114,0,148,1) 0%,rgba(38,226,205,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(114,0,148,1) 0%,rgba(38,226,205,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c2720094', endColorstr='#c226e2cd',GradientType=0 ); /* IE6-9 */
}

@mixin overlay($colorcode, $strength){
    content:'';
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    mix-blend-mode: multiply;
    z-index:10;
    @if $colorcode == 'black' {
        background-color: rgba(#000000, $strength);
    }
    @else{
        background-color:rgba(25, 25, 25, 0.66);
    }
}
